/** note: legacy css document, no further changes or updates here */

:root {
    --ff-sans-serif: 'InterVariable', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    --color-aqua: #36a6c2;
    --red: #f25c51;
    --aqua: #1CB9CA;
    --blue: #2c81c2;
    --white: #FFFFFF;
    --gray: #9c9c9c;
    --green: #26a37a;
    --lightred: #FF948D;
    --lightgreen: #4ad0a0;
    --lightblue: #63afe8;
    --lightgray: #cdcdcd;
    --aquadark: #008080;
    --black: #000;
    --navy: #000080;
}

body {
    font: normal 14px/22px var(--ff-sans-serif);
    background: #fff;
    color: #000;
}


/* --------------------- Common css classes start here ----------------------  */

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

html {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
}

nav li {
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

h1 {
    font-size: 20px;
    color: #434343;
    line-height: 30px;
}

a {
    text-decoration: none;
    color: #607986;
}

a:hover {
    color: #265083;
}

img {
    max-width: 100%;
}

textarea {
    resize: none;
    /*font-family:tahoma;*/
    font-size: 13px;
    padding: 4px;
    color: var(--black);
}

#quick-note {
    border: 1px solid var(--color-aqua);
    padding: 4px;
    margin: 2px;
}

input,
select {
    padding: 4px;
    color: var(--black);
    font-family: var(--ff-sans-serif);
}

option:disabled,
select[disabled]>option {
    color: lightgrey;
}

table td {
    vertical-align: top;
}

* {
    outline: none;
}

#loginpage #layout-outer {
    overflow: auto;
}

#layout-outer {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    background: var(--aquadark);
}

h2.blue-bg {
    font-weight: bold;
    font-size: 14px;
    color: #f7f7f7;
    text-transform: uppercase;
    padding: 0 16px 0px;
    line-height: 42px;
    background: #049acb;
    background: -moz-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(2, 151, 200, 1) 95%, rgba(0, 135, 179, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, rgba(26, 187, 242, 1)), color-stop(96%, rgba(57, 168, 213, 1)), color-stop(100%, rgba(0, 105, 139, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* W3C */
    -moz-box-shadow: 1px 1px 2px #c9c9c9;
    -webkit-box-shadow: 1px 1px 2px #c9c9c9;
    box-shadow: 1px 1px 2px #c9c9c9;
}

h2.blue-bg2 {
    font-weight: bold;
    font-size: 14px;
    color: #f7f7f7;
    padding: 0 16px 0px;
    line-height: 42px;
    background: #049acb;
    background: -moz-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(2, 151, 200, 1) 95%, rgba(0, 135, 179, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, rgba(26, 187, 242, 1)), color-stop(96%, rgba(57, 168, 213, 1)), color-stop(100%, rgba(0, 105, 139, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(26, 187, 242, 1) 1%, rgba(57, 168, 213, 1) 96%, rgba(0, 105, 139, 1) 100%);
    /* W3C */
    -moz-box-shadow: 1px 1px 2px #c9c9c9;
    -webkit-box-shadow: 1px 1px 2px #c9c9c9;
    box-shadow: 1px 1px 2px #c9c9c9;
}

h2 .close {
    background: #00465d;
    color: #ffff;
    padding: 0px;
    margin-left: px;
    width: 24px;
    height: 21px;
    float: right;
    line-height: 20px;
    text-align: center;
    margin: 10px 0 0 0;
    *margin: -31px 10px 0 0;
    font-size: 10px;
    font-weight: bold;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 1px 1px 2px #34b6e2;
    -webkit-box-shadow: 1px 1px 2px #34b6e2;
    box-shadow: 1px 1px 2px #34b6e2;
}

#header {
    height: 55px;
    position: fixed;
    right: 0;
    z-index: 20;
    left: 200px;
    padding-left: 20px;
    background: var(--white);
    border-bottom: 1px solid #eee;
    /* box-shadow: 0px 1px 0px #f6f6f6; */
}

#logo {
    float: left;
    height: 50px;
    width: 210px;
    margin-left: 4px;
}

#logo span {
    width: 100%;
    float: left;
}

img.logo-img {
    margin: 11px 0 0 12px;
    height: 30px;
}

.main-menu {
    float: left;
    margin-top: 10px;
    padding-right: 2px;
}

.main-menu img {
    max-width: 100%;
}

.main-menu li {
    float: left;
    list-style: none;
    line-height: 12px;
    border: 0px solid #ebebeb;
    width: auto;
    margin-right: 10px;
    position: relative;
    padding-bottom: 7px;
}

.main-menu li:hover {
    border: 0px solid #ebebeb;
}

.main-menu li a {
    font-size: 13px;
    color: #fff;
    display: block;
    border: 0px solid #cfcfcf;
    padding: 8px 25px 8px 9px;
    float: left;
    border-radius: 3px;
    position: relative;
}

.main-menu li a:hover {
    color: #fff;
    border: 0px solid #cfcfcf;
}

.main-menu li a.btn {
    padding: 0;
}

.main-menu li span.material-icons {
    margin: 2px 4px;
    font-size: 16px;
    float: left;
}

.main-menu li span.menu-text {
    line-height: 20px;
    padding: 3px 8px 3px 4px;
    float: left;
}

.main-menu li span.menu-text .edi-count {
    display: none;
    border: 1px solid #fff;
    background: #c94e45;
    color: #fff;
    float: left;
    margin-right: 4px;
    width: 18px;
    height: 18px;
    text-indent: -1px;
    line-height: 16px;
    border-radius: 50%;
    font-size: 11px;
}

.main-menu li span.divider {
    border-left: 1px solid #73cdac;
    line-height: 20px;
    padding: 3px 0;
    margin: 0;
}

.main-menu li a.btn-invert span.divider {
    border-left-color: #707b80;
}

.main-menu li a.btn-generic:hover {
    background: var(--green);
}

.main-menu li a.btn-invert:hover {
    background: #626f75;
}

.main-menu li a.btn-alert,
.main-menu li a.btn-alert:hover {
    background: #f25c51;
}

a.accounts {
    background: url(/skin/images/accounts-icon.jpg) no-repeat 50% 7px;
}

a.accounts:hover {
    background: url(/skin/images/accounts-icon-h.jpg) no-repeat 50% 7px;
}

a.user {
    background: url(/skin/images/users-icon.jpg) no-repeat 50% 7px;
}

a.user:hover {
    background: url(/skin/images/users-icon-h.jpg) no-repeat 50% 7px;
}

a.rotate {
    background: url(/skin/images/rotate-icon.jpg) no-repeat 50% 7px;
}

a.rotate:hover {
    background: url(/skin/images/rotate-icon-h.jpg) no-repeat 50% 7px;
}

a.cycle {
    background: url(/skin/images/cycle-icon.jpg) no-repeat 50% 7px;
}

a.cycle:hover {
    background: url(/skin/images/cycle-icon-h.jpg) no-repeat 50% 7px;
}

a.plus {
    background: var(--green);
}

a.plus span {
    background: url(/skin/images/plus-icon-v1.png) no-repeat 0px 0px;
    padding-left: 11px;
}


/*a.plus:hover{background:url(/skin/images/plus-icon-h.jpg) no-repeat  50% 7px;}*/

input.plus {
    background-image: url(/skin/images/plus-icon-v1.png) !important;
    background-repeat: no-repeat !important;
    background-position: 8px 50% !important;
    padding-left: 22px !important;
}

a.minus {
    background: #626f75;
}

a.minus span {
    background: url(/skin/images/minus-icon-v1.png) no-repeat 0px 0px;
    padding-left: 15px;
}


/*a.minus:hover{background:url(/skin/images/minus-icon-h.jpg) no-repeat  50% 7px;}*/

.arrow_div_v1 {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 17px;
    background: url(/skin/images/arrow_dp_v1.png) no-repeat center center;
}

a.plus .arrow_div_v1 {
    border-left: 1px solid #73cdac;
}

a.minus .arrow_div_v1 {
    border-left: 1px solid #707b80;
}

.main-menu li .sub_menu_v1 {
    position: absolute;
    background: #fff;
    border: 1px solid #ececec;
    top: 35px;
    min-width: 170px;
    padding: 5px 0px;
    box-shadow: 0px 0px 1px #ececec;
    display: none;
}

.main-menu li .sub_menu_v1:before {
    content: "";
    padding-top: 10px;
    background: url(/skin/images/arrow_submenu_top.png) no-repeat 0px 0px;
    position: absolute;
    top: -7px;
    height: 3px;
    width: 13px;
    left: 60px;
}

.main-menu li .sub_menu_v1 li a {
    color: #394246;
    float: none;
    padding: 8px 10px;
    text-align: left;
}

.main-menu li .sub_menu_v1 li a:hover {
    color: #53b8d3;
    background: #f1f8fa
}

.main-menu li .sub_menu_v1 li {
    float: none;
    margin-right: 0px;
    padding-bottom: 0px;
}

.main-menu li .sub_menu_v1 li.list-item {
    border-bottom: 1px solid #eee;
    line-height: 18px;
    padding: 4px 8px;
    cursor: default;
}

.main-menu li .sub_menu_v1 li.list-item:last-child {
    border-bottom: none;
}

.main-menu li:hover .sub_menu_v1 {
    display: block;
}

.sub_menu_v1 li {
    cursor: pointer;
}

#top-right {
    float: right;
    margin: 10px 17px 0 0;
}

.top-right-nav {
    min-width: 1px;
    background: #eceded;
    color: #caddf3;
    padding: 0 0 0 10px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    position: relative;
    float: right;
    height: 35px;
}

.top-right-nav li {
    cursor: pointer;
    padding-bottom: 8px;
    padding-right: 30px;
}

.top-right-nav li span {
    background: url(/skin/images/user_icon_v1.png) no-repeat left center;
    padding-left: 15px;
}

.top-right-nav li li span {
    background: none;
}

.top-right-nav span {
    display: inline-block;
    line-height: 35px;
}

.top-right-nav span a {
    font-weight: normal;
    color: #607986;
    margin-left: 3px;
}

.top-right-nav li ul.sub_menu_v1 {
    position: absolute;
    background: #fff;
    border: 1px solid #ececec;
    top: 35px;
    min-width: 170px;
    padding: 5px 0px;
    box-shadow: 0px 0px 1px #ececec;
    right: -5px;
    display: none;
    z-index: 500;
}

.top-right-nav li .sub_menu_v1:before {
    content: "";
    padding-top: 10px;
    background: url(/skin/images/arrow_submenu_top.png) no-repeat 0px 0px;
    position: absolute;
    top: -7px;
    height: 3px;
    width: 13px;
    right: 6px;
}

.top-right-nav li:last-child {
    border-bottom: none;
}

.top-right-nav li:hover ul {
    display: block;
}

.top-right-nav li .sub_menu_v1 li a {
    color: #394246;
    float: none;
    padding: 8px 10px;
    text-align: left;
    display: block;
    line-height: normal;
}

.top-right-nav li .sub_menu_v1 li:hover,
.top-right-nav li .sub_menu_v1 li a:hover {
    color: #53b8d3;
    background: #f1f8fa
}

.top-right-nav li .sub_menu_v1 li {
    float: none;
    margin-right: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    position: relative;
}

.top-right-nav .arrow_div_v1 {
    background: url(/skin/images/arrow_dp_v2.png) no-repeat center center;
    border-left: 1px solid #e3e4e4;
}

.top-right-nav li .sub_menu_v1 li>.dropdown {
    border: 1px solid #ececec;
    box-shadow: 0px 0px 1px #ececec;
    background: #fff;
    position: absolute;
    width: 170px;
    left: -170px;
    z-index: 2;
    top: 0;
    display: none;
}

.top-right-nav li .sub_menu_v1 li:hover .dropdown {
    display: block;
}

.top-right-nav .dropdown li {
    margin: 0;
}

.top-right-nav .dropdown li.current {
    font-weight: bold;
}

.notification-div {
    float: right;
    /*font-family:Tahoma;*/
    font-size: 11px;
    font-weight: bold;
    margin-right: 8px;
    margin-top: 0px;
    color: #282828;
}

.notification-div input {
    border: 1px solid #e2e3e3;
    border-radius: 3px;
    padding: 6px;
    padding-top: 7px;
    margin-top: 0px;
}

.mail_box {
    float: right;
    margin-right: 8px;
}

.mail_box a {
    background: #B7C7C7;
    padding: 3px 10px 4px 10px;
    padding-bottom: 3px\9;
    display: block;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
}

.mail_box a.has_mail {
    background-color: #f25c51;
}

.mail_box a span {
    background: url(/skin/images/mail-icon-v1.png) no-repeat left center;
    padding-left: 22px;
}

.top_link_v1 {
    float: right;
    margin-right: 21px;
    padding-top: 2px;
}

.top_link_v1 li {
    list-style: none;
    font-size: 13px;
}

.notification-div span {
    background: #343434;
    color: #e1e1e1;
    padding: 4px 6px;
    margin-left: 8px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -moz-box-shadow: 1px 1px 2px #f8f8f9;
    -webkit-box-shadow: 1px 1px 2px #f8f8f9;
    box-shadow: 1px 1px 2px #f8f8f9;
}

.notification-div img {
    float: left;
    margin: 0 8px 0 0;
}

#left-section {
    box-shadow: -2px -10px 15px inset rgba(0, 0, 0, 0.2);
    background: var(--aquadark);
    border-right: 1px solid #e7e9ea;
    width: 212px;
    padding-right: 0px;
    height: 100%;
    float: left;
    position: fixed;
    top: 0;
    bottom: 0;
}

html.has-notification {
    overflow: hidden;
}

html.has-notification #layout-outer {
    top: 40px;
}

html.has-notification #left-section {
    top: 91px;
}

.left-main-nav {
    padding: 0 0 0 16px;
    overflow: auto;
    position: absolute;
    top: 50px;
    bottom: 240px;
    width: 103%;
}

.left-main-nav li {
    list-style: none;
    line-height: 34px;
    transition-duration: 200ms;
    border-radius: 5px;
}

.left-main-nav li a {
    color: var(--white);
    display: block;
    padding-left: 10px;
    background: url('/skin/images/right-white-arrow.png') no-repeat center left;
    font-size: 13px;
    font-weight: bold;
    text-shadow: 1px 1px var(--black);
}

.left-main-nav li ul {
    padding: 0;
    width: 165px;
}

.left-main-nav li li:hover {
    background: rgba(232, 235, 237, 0.5);
}

.left-main-nav li li:hover a {
    color: var(--black);
}

.left-main-nav li li a {
    font-weight: normal;
    color: var(--white);
    background: none;
    padding: 0 0 0 16px;
    text-shadow: none;
}

.left-btm-nav {
    background: #fff;
    border-top: 1px solid #e8e9e9;
    height: 239px;
    overflow-y: scroll;
    position: fixed;
    bottom: 0;
    width: 216px;
}

.left-btm-nav li {
    list-style: none;
    font-size: 13px;
    font-weight: normal;
    padding: 6px 8px;
    text-align: left;
    min-height: 27px;
    background: #fafafa;
}

.left-btm-nav li>div {
    background: var(--white);
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #f0f0f0;
}

.left-btm-nav li img,
.left-btm-nav li .activity-placeholder {
    float: left;
    margin-right: 5px;
    width: 23px;
    height: 23px;
    margin-top: 3px;
}

.left-btm-nav li .activity-placeholder .material-icons {
    color: rgb(125, 125, 125);
}

.left-btm-nav li span {
    color: #4D4D4D;
    font-weight: normal;
}

.left-btm-nav li>div>span:first-of-type {
    font-weight: bold;
}

.left-btm-nav h3 {
    padding: 5px 10px;
    border-bottom: 1px solid #ddd;
    text-transform: uppercase;
    font-size: 11px;
}

#right-section {
    position: absolute;
    top: 0;
    right: 0px;
    left: 200px;
    width: auto;
    min-height: 100vh;
    overflow-y: auto;
    background: var(--white);
    overflow-x: auto;
    padding-top: 70px;
}


/* --------------------- Common css classes end here ----------------------  */


/* ------------------------ Login page css start here ------------------------ */

.login-section {
    width: 350px;
    border-radius: 4px;
    background: var(--white);
    border: 1px solid #d2d2d2;
    overflow: visible;
    box-shadow: 1px 1px 10px #777;
}

.login-bg {
    width: 288px;
    margin: 20px auto 28px;
}

.login-section .logo-img {
    margin: 10px auto;
}

.login-section h2 {
    width: 100%;
    margin-top: -70px;
    padding-bottom: 20px;
}

.login-section label {
    font-weight: bold;
    color: #333;
    text-transform: capitalize;
    margin-bottom: 6px;
    text-align: left;
    display: block;
}

.login-section input.input-bg,
.login-section input.input-bg:focus,
.login-section input.input-bg:active {
    background: #fff;
    border: 1px solid #e2e3e3;
    border-radius: 3px;
    margin-right: 0px;
    margin-bottom: 20px;
    width: 100%;
    display: block;
    padding: 5px 10px;
}

.login-section .input-bg.required.error {
    background: rgba(242, 92, 81, 0.3);
    border: 1px solid var(--red);
}

.login-btn {
    padding: 10px 14px;
    font-size: 13px;
    color: #fff;
    border-radius: 3px;
    border: 1px solid #000;
    cursor: pointer;
    background: #5e5e5e;
    /* Old browsers */
    background: -moz-linear-gradient(top, #5e5e5e 4%, #000000 9%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #5e5e5e 4%, #000000 9%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #5e5e5e 4%, #000000 9%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5e5e5e', endColorstr='#000000', GradientType=0);
    /* IE6-9 */
    text-shadow: 1px 1px 0 #000;
}

span.small-black a {
    color: #232323;
    margin-left: 17px;
}

span.small-black a:hover {
    color: #265083;
}

.login-section p.error {
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--red);
}

.login-section label.error {
    color: var(--white);
    background: var(--red);
    border-radius: 3px;
    text-align: center;
    position: relative;
    padding: 4px;
    width: 96.5%;
    margin: 5px 0 10px 0;
    top: -15px;
}

@media only screen and (max-width: 480px) {
    .login-section {
        width: 100%;
    }

    .login-bg {
        background: none;
        margin: 20px 4% 45px;
        width: 90%;
    }

    .login-section label {
        float: none;
        width: 100%;
    }

    .login-section input.input-bg {
        width: 97%;
    }
}

@media only screen and (max-width: 550px) {
    .login-section {
        width: 95.6%;
        margin: 8% 2% 0px;
    }
}


/* ------------------------ Login page css end here ------------------------ */


/* ------------------------ Home page css start here ------------------------ */

.system-nav {
    padding: 19px 29px;
}

.system-nav li {
    font-size: 14px;
    color: #4a4a4a;
    border-bottom: 1px solid #c1c1c1;
    background: url(/skin/images/right-arrow.png) 100% 12px no-repeat;
    padding: 7px 14px 7px 0px;
}

.system-nav li:last-child {
    border-bottom: none;
}

.system-nav li span {
    font-weight: bold;
    font-size: 14px;
    color: #000000;
    float: right;
    *margin-top: -29px;
    *margin-right: 20px;
}


/* ------------------------ Home page css end here ------------------------ */


/* ------------------------ Control Panel css start here ------------------------ */

.content-top-details {
    padding: 14px 22px 12px 22px;
    clear: both;
    float: left;
    width: 100%;
    background: #ffffff;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.content-top-details select {
    background: #eceded url(/skin/images/select_arrow_bg.png) no-repeat right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 5px 7px 10px;
    border-radius: 3px;
    border-color: var(--gray);
    cursor: pointer;
    margin-right: 3px;
    width: 114px;
}

.content-top-details .meta {
    clear: both;
    margin-top: 10px;
    color: #777;
    float: left;
}

.content-top-details .datepicker {
    background: #fff url('../images/calendar-icon.gif') no-repeat 99% 50%;
    border: 1px solid #eee;
    color: #777;
}

.content-top-details .datepicker:hover {
    background-color: #eee;
    color: #000;
}

.content-top-details .meta.rounded {
    border-radius: 6px;
    padding: 0 7px;
    background: #eee;
    font-size: 11px;
}

.content-top-details input,
.btn-generic {
    background: var(--green);
    border: none;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    padding: 5px 12px 6px;
    cursor: pointer;
    margin-bottom: 5px;
}

.btn-invert {
    background: #626f75;
}

.content-top-details input:hover,
.btn-generic:hover {
    background-color: #090;
}

.content-top-details input.pick_pallet {
    background: var(--green) url(/skin/images/pick_icon_v1.png) no-repeat 9px center;
    padding-left: 24px;
}

.content-top-details input.delete_pallet {
    background: #f0f1f1 url(/skin/images/delete_icon_v1.png) no-repeat 9px center;
    padding-left: 24px;
    color: #607986;
}

.content-top-details input.edit_pallet {
    background: #f0f1f1 url(/skin/images/edit_icon_v1.png) no-repeat 9px center;
    padding-left: 27px;
    color: #607986;
}

.editform .form-control[type=text] {
    border: 1px solid #ccc;
}

.content-top-details .inventory_buttons input {
    margin: 0px;
    margin-right: -3px;
}

.content-top-details input[type=text] {
    background: var(--lightgray);
    border: 1px solid var(--gray);
    color: var(--black);
    box-shadow: 1px 1px 1px inset var(--gray);
}

.btn-generic:hover {
    color: #fff;
}

#palletOverlay {}

#palletOverlay input[type=submit],
#palletOverlay input[type=button] {
    background: var(--green);
    border: none;
    text-align: center;
    color: #fff;
    border-radius: 3px;
    padding: 5px 12px 6px;
    cursor: pointer;
    font-size: 13px;
}

.ui-autocomplete .ui-menu-item a,
.ui-autocomplete .ui-menu-item a.ui-state-hover {
    font-size: 14px;
    padding: 8px 6px;
    background: var(--white);
    border-radius: 0;
    border: 0;
    margin: 0;
    border-bottom: 1px solid var(--green);
}

.ui-autocomplete .ui-menu-item a.ui-state-hover {
    background: var(--lightblue);
    color: var(--white);
}

#right-section h1 {
    float: left;
    font-weight: bold;
}

.nav-btn {
    margin-right: 12px;
    float: left;
}

.previous {
    padding: 0px;
    margin: 0px;
    border: none;
    float: left;
    background: url(/skin/images/previous-btn.png) no-repeat 0 0;
    width: 29px;
    height: 28px;
    text-indent: -999em;
    border-right: 1px solid #cfcfcf;
    cursor: pointer;
}

.next {
    padding: 0px;
    margin: 0px;
    border: none;
    float: left;
    background: url(/skin/images/next-btn.png) no-repeat 0 0;
    width: 29px;
    height: 28px;
    text-indent: -999em;
    cursor: pointer;
}

.select-area {
    float: right;
    margin-top: 4px;
}

.select {
    float: left;
}

.select label {
    font-size: 14px;
    color: #1a1a1a;
    margin: 0 6px 0 18px;
}

.select select {
    height: 29px;
    color: #1a1a1a;
    border: 1px solid #cccccc;
    padding: 4px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: -moz-linear-gradient(top, rgba(231, 231, 231, 1) 0%, rgba(249, 249, 249, 1) 6%, rgba(239, 239, 239, 1) 94%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(231, 231, 231, 1)), color-stop(6%, rgba(249, 249, 249, 1)), color-stop(94%, rgba(239, 239, 239, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(231, 231, 231, 1) 0%, rgba(249, 249, 249, 1) 6%, rgba(239, 239, 239, 1) 94%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(231, 231, 231, 1) 0%, rgba(249, 249, 249, 1) 6%, rgba(239, 239, 239, 1) 94%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(231, 231, 231, 1) 0%, rgba(249, 249, 249, 1) 6%, rgba(239, 239, 239, 1) 94%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(231, 231, 231, 1) 0%, rgba(249, 249, 249, 1) 6%, rgba(239, 239, 239, 1) 94%);
    /* W3C */
}

#clients {
    width: 171px;
}

#search {
    width: 141px;
}

#quantity {
    width: 70px;
}

.list {
    clear: both;
}

.list .table {
    background: #b6b6b6;
    display: block;
}

.list .table .users-listing {
    width: 100%
}

.users-listing {
    color: #282828;
}

.users-listing tr th {
    height: 31px;
    text-align: left;
    padding: 0 12px;
    font-weight: bold;
    background: -moz-linear-gradient(top, rgba(249, 249, 249, 1) 1%, rgba(249, 249, 249, 1) 7%, rgba(222, 222, 222, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, rgba(249, 249, 249, 1)), color-stop(7%, rgba(249, 249, 249, 1)), color-stop(100%, rgba(222, 222, 222, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(249, 249, 249, 1) 1%, rgba(249, 249, 249, 1) 7%, rgba(222, 222, 222, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(249, 249, 249, 1) 1%, rgba(249, 249, 249, 1) 7%, rgba(222, 222, 222, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(249, 249, 249, 1) 1%, rgba(249, 249, 249, 1) 7%, rgba(222, 222, 222, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(249, 249, 249, 1) 1%, rgba(249, 249, 249, 1) 7%, rgba(222, 222, 222, 1) 100%);
    /* W3C */
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#f9f9f9', endColorstr='#dedede', GradientType=0);
    /* IE6-8 */
}

.users-listing tr td {
    background: #FFFFFF;
    padding: 2px 12px;
    font-size: 13px;
}

.users-listing tr td.td-gray-bg {
    background: #ededed;
    text-align: center;
    padding: 2px 0px;
    font-size: 13px;
}


/* ------------------------ Control Panel css end here ------------------------ */


/* ------------------------- Add Stock css start here ------------------------- */

.breadcrumbs {
    border-bottom: 1px solid #dfdfdf;
    padding: 1.3%;
    color: #c9c9c9;
    font-size: 13px;
}

.breadcrumbs a {
    color: #7b7b7b;
    margin: 0 8px;
}

.breadcrumbs a.last,
.breadcrumbs a:hover {
    color: #000000;
}

.add-table {
    padding: 8px 0;
    border-collapse: separate;
    width: 100%;
}

form .add-table {
    max-width: 760px;
    margin: 0 auto;
}

.add-table label {
    font-weight: bold;
    font-size: 13px;
    min-width: 112px;
    display: block;
    text-align: left;
    margin-bottom: 5px;
    clear: both;
}

.add-table input[type="checkbox"] {
    float: left;
    margin-top: 5px;
    margin-right: 8px;
}

.add-table dd {
    clear: both;
    display: block;
}

.add-table .form-subtitle-container {
    color: #616d73;
}

.add-table .form-subtitle {
    border-bottom: 1px dashed #ccc;
    padding: 0 0 5px 0;
}

.add-table .form-subtitle-container .icon-plus {
    font-weight: bold;
    position: relative;
    top: -16px;
    left: -12px;
    padding: 3px 9px;
    border-radius: 100px;
    background: rgb(250, 250, 250);
    border: 1px solid #ccc;
    font-size: 20px;
}

.add-table .form-subtitle-container .icon-plus:hover {
    background: #eee;
    color: #333;
}

.add-table .input-area1 {
    margin-bottom: 15px;
}

span.span1 {
    color: #7f7e7e;
    float: right;
    line-height: 18px;
    margin-left: 4px;
}

span.span2 {
    font-size: 11px;
    color: #7f7e7e;
    /*font-family:Tahoma;*/
    /*float:right;*/
    line-height: 22px;
    margin-left: 8px;
    font-weight: normal;
}

.input-area1 {
    width: 231px;
    background: #fefefe;
    border: 1px solid #a8a8a8;
    border-radius: 3px;
    margin-right: 10px;
}

.select-area1 {
    width: 241px;
    background: #fff;
    border: 1px solid #e2e3e3;
    border-radius: 3px;
    margin-right: 10px;
}

select.select-area1 {
    border: 1px solid #a8a8a8;
    padding: 5px 10px;
}

.add-table .input-area1 {
    width: 100%;
    margin-right: 0px;
}

.add-table .select-area1,
.add-table select {
    width: 100%;
    margin-right: 0px;
    border: 1px solid #e2e3e3;
}

.add-table textarea {
    height: 66px;
    width: 262px;
    background: #fff;
    border: 1px solid #e2e3e3;
    border-radius: 3px;
}

.add-table select:disabled,
.add-table input:disabled {
    color: #aaa;
}

.submit-btn1 {
    background: #eeeeee;
    cursor: pointer;
    font-size: 11px;
    color: #434343;
    /*font-family:Tahoma;*/
    padding: 5px 5px;
    margin-right: 10px;
    margin-left: 0px;
    border: 0px solid #c8c8c8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.submit-btn2 {
    padding: 5px 10px;
}

.submit-btn1:hover {
    background: #3d9aee;
    color: #FFFFFF;
}

.add-table .submit-btn1 {
    background: var(--green);
    padding: 7px 15px;
    color: #fff;
    font-size: 13px;
    margin-right: 0px;
    margin-left: 2px;
}

.add-table .submit-btn1#cancel {
    background: #626f75;
}

.add-table .submit-btn1#back {
    background: #eceded;
    color: #607986;
}

.add-table .submit-btn1#help {
    background: #eceded;
    color: #607986;
}


/* ------------------------- Add Stock css end here --------------------------- */


/* ------------------------- Add Inventory css start here ---------------------- */

.search {
    background: url(/skin/images/search-icon.png) no-repeat;
    width: 35px;
    height: 27px;
    border: none;
    float: right;
    cursor: pointer;
}


/* ------------------------- Add Inventory css end here ---------------------- */


/* ------------------------- Add resources css start here -------------------- */

.add-resource {
    padding: 0 1.8%;
    width: 96.4%;
    clear: both;
    float: left;
}

.resource-nav {
    float: left;
    margin-right: 45px;
    width: 180px;
}

.resource-nav li {
    margin: 13px 0;
    min-width: 165px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 1px 1px 0 #C8C8C8;
    -webkit-box-shadow: 1px 1px 0 #C8C8C8;
    box-shadow: 1px 1px 0 #C8C8C8;
}

.resource-nav li a {
    /*font-family:Tahoma;*/
    font-size: 13px;
    color: #4b4b4b;
    background: url(/skin/images/right-dark-arrow.png) no-repeat 10px 11px #e9e9e9;
    padding: 0 15px 0 20px;
    line-height: 26px;
    display: block;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.resource-nav li a:hover {
    color: #fff;
    background: url(/skin/images/right-white-arrow.png) no-repeat 10px 11px #2d9cc9;
}

.resource-btn {
    float: left;
    clear: both;
    margin: 18px 0;
}


/* ------------------------- Add resources css end here -------------------- */


/* ------------------------- Clients details css start here ---------------- */

#tabs {
    float: left;
    padding: 1.8%;
    /*width: 96.4%;*/
    min-width: 96.4%;
}

#tabs small {
    font-size: 10px;
}

.client-details li {
    float: left;
    border: 1px solid #ddd;
    padding: 6px 15px;
}

.nav-tabs:before,
.nav-pills:before,
.nav-tabs:after,
.nav-pills:after {
    display: table;
    content: "";
}

.nav-tabs:after {
    clear: both;
    height: 7px;
}

.nav-tabs>li {
    float: left;
    margin-bottom: -8px;
    list-style: none;
}

.nav-tabs>li>a {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: 2px;
    line-height: 14px;
}

.nav-tabs>li>a {
    float: left;
    text-align: center;
    padding: 8px 18px;
    line-height: 18px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    border: 1px solid #ddd;
    border-bottom: none;
    font-size: 13px;
    background: #f8f8f8;
    background: rgb(255, 255, 255);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(225, 225, 225, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(225, 225, 225, 1) 100%);
    /* W3C */
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e1e1e1', GradientType=0);
    /* IE6-8 */
}


/*.nav-tabs > li > a:hover { border-color: #706f6f; background:#706f6f; color:#f8f8f8; border-bottom:none;}*/

.nav-tabs>.active>a:hover {
    color: #656565;
    background: url(/skin/images/tab-hover.png) no-repeat 0 0 #706f6f;
    color: #f8f8f8;
    border-color: #706f6f;
    cursor: default;
}

.nav-tabs>.active>a,
.nav-tabs li.active:hover a,
.nav-tabs>li>a:hover {
    background: rgb(225, 225, 225);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(225, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(225, 225, 225, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(225, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(225, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(225, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(225, 225, 225, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* W3C */
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#e1e1e1', endColorstr='#ffffff', GradientType=0);
    /* IE6-8 */
    border: 1px solid #ddd;
    border-bottom: 1px solid #fff;
}

.nav-tabs li.active:hover a {
    color: #656565;
}

.nav-tabs .datepicker {
    border: 1px solid #eee;
    margin-left: 10px;
    padding: 6px;
}

.nav-tabs .datepicker-label {
    margin-bottom: -5px;
    margin-left: 10px;
    display: block;
    font-size: 10px;
}

.tab-content {
    background: #FFFFFF;
    border: 1px solid #ddd;
    padding: 20px;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.add-table2 td {
    background: #FFFFFF;
}


/* ------------------------- Clients details css end here ---------------- */


/* Mobile css start here */

#layout-outer-m {
    width: 100%;
    margin: 0px auto;
    background: #f4f4f4;
    padding: 0px;
    height: 100%;
    overflow: hidden;
    top: 0;
}

#header-m {
    background: url(/skin/images/top-header-bg.jpg) repeat-x bottom center #f5f5f5;
    height: 45px;
    position: fixed;
    width: 100%;
    z-index: 5;
}

#logo-m {
    float: left;
    height: 45px;
    width: 26.2%;
    background: -moz-linear-gradient(top, rgba(110, 201, 238, 1) 0%, rgba(57, 168, 213, 1) 95%, rgba(38, 144, 187, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(110, 201, 238, 1)), color-stop(95%, rgba(57, 168, 213, 1)), color-stop(100%, rgba(38, 144, 187, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(110, 201, 238, 1) 0%, rgba(57, 168, 213, 1) 95%, rgba(38, 144, 187, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(110, 201, 238, 1) 0%, rgba(57, 168, 213, 1) 95%, rgba(38, 144, 187, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(110, 201, 238, 1) 0%, rgba(57, 168, 213, 1) 95%, rgba(38, 144, 187, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, rgba(110, 201, 238, 1) 0%, rgba(57, 168, 213, 1) 95%, rgba(38, 144, 187, 1) 100%);
    /* W3C */
}

#logo-m span {
    background: url(/skin/images/blue-dvd.jpg) repeat-y right center;
    width: 100%;
    float: left;
    height: 45px;
}

.logo-img-m {
    max-width: 100%;
    margin: 5px 0 0 15px;
}

.main-menu-m {
    float: left;
    margin-top: 5px;
    width: 73.8%;
    padding-left: 0px;
}

.main-menu-m li {
    float: left;
    list-style: none;
    width: auto;
    text-align: center;
    line-height: 12px;
    background: url(/skin/images/dvd-m.jpg) no-repeat right center;
    margin: 4px 0;
}

.main-menu-m li:hover {
    border: 0px solid #ebebeb;
}

.main-menu-m li a {
    font-size: 1em;
    color: #4f4f4f;
    text-transform: uppercase;
    display: block;
    border: 0px solid #cfcfcf;
    padding: 0px 10px;
    display: block;
    float: left;
    line-height: 18px;
}

.main-menu-m li a:hover {
    color: #2b2b2b;
    border: 0px solid #cfcfcf;
}

#mobile #right-section {
    background: none repeat scroll 0 0 #F4F4F4;
    bottom: 0;
    left: 25.8%;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 45px;
    width: auto;
}

#left-section-m {
    background: url(/skin/images/left-shadow-bg.png) repeat-y right center #ececec;
    width: 25.8%;
    padding-right: 2px;
    height: 100%;
    float: left;
    position: fixed;
    top: 45px;
    bottom: 0;
}

.left-main-nav-m {
    padding: 12px 0 0 8px;
}

.left-main-nav-m li {
    list-style: none;
    line-height: 25px;
}

.left-main-nav-m li a {
    font-size: 0.8em;
    font-weight: bold;
    color: #767b82;
    background: url(/skin/images/right-arrow.jpg) no-repeat 0 3px;
    padding: 0 0 0 13px;
}

.left-main-nav-m li ul {
    margin: 0 0 0 12px;
    padding: 0px;
    position: absolute;
    left: -1000em;
}

.left-main-nav-m li:hover ul {
    display: block;
    left: auto;
    position: relative;
}

.left-main-nav-m li li {
    line-height: 20px;
}

.left-main-nav-m li li a {
    font-weight: normal;
    color: #111111;
    background: url(/skin/images/folder-icon.jpg) no-repeat 0 0px;
    padding: 0 0 0 10px;
}


/* Mobile css end here */


/* iPhone, etc landscape ---- */

@media only screen and (min-width: 480px) and (max-width: 480px) {
    #left-section-m {
        width: 23%;
    }

    #logo-m {
        width: 23%;
    }

    #mobile #right-section {
        left: 19%
    }
}


/* */

.ui-jqgrid input,
select {
    padding: 0px;
}

.ui-jqgrid-titlebar {
    display: none !important;
}

.ui-row-ltr:nth-child(even) {
    background: #effbff;
}

.ui-row-ltr.ui-state-highlight {
    background: #88d4e9;
    color: #000;
}

.ui-row-ltr.ui-state-hover {
    background: #d0f5ff;
    color: #000;
}

.ui-row-ltr.ui-state-highlight,
ui-state-hover {
    background: #88d4e9;
}

.prod_list_a {
    float: left;
}

.ui-jqgrid .ui-jqgrid-htable th div {
    text-transform: uppercase;
}

#pallet_tbl .ui-jqgrid-btable td {
    text-align: center !important;
}

.heading_a label {
    font-size: 18px;
    color: #434343;
    line-height: 30px;
}

.heirarchy div {
    margin: 5px 0px !important;
}

.select select {
    width: 150px;
}

.close_red_icon img {
    margin-bottom: -15px;
    cursor: pointer;
}

.list_btns li a {
    background: #eeeeee;
    cursor: pointer;
    font-size: 11px;
    color: #434343;
    /*font-family:Tahoma;*/
    padding: 5px 5px;
    margin-right: 10px;
    margin-left: 0px;
    border: 1px solid #c8c8c8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.list_btns li a:hover {
    background: #3d9aee;
    border: 1px solid #298eea;
    color: #FFFFFF;
}

.list_btns li {
    list-style: none;
    float: left;
}

.list_btns {
    margin-top: 10px;
    margin-bottom: 10px;
    float: left;
}


/*
Amit Garg 10 June 2013
*/

.center-timeline {
    bottom: 0;
    margin: 0 18px;
    padding-top: 20px;
}

.center-timeline li {
    list-style: none;
    color: var(--black);
    font-weight: normal;
    font-size: 13px;
    padding: 8px 0px;
    line-height: 14px;
    text-align: left;
    min-height: 50px;
    text-indent: 0px;
    clear: both;
    margin-left: 63px;
}

.center-timeline li a {
    color: #607986;
}

.center-timeline li img,
.center-timeline li .activity-placeholder {
    float: left;
    margin-right: 5px;
    border: 1px solid #e3e3e3;
    padding: 3px;
    border-radius: 30px;
    margin-left: -63px;
}

.center-timeline li .activity-placeholder {
    border: 0;
}

.center-timeline li .activity-placeholder .material-icons {
    color: #129ac3;
    font-size: 45px;
    top: 0;
}

.center-timeline li a.grouped_elements img {
    margin-left: 0px;
    border: 0px;
    padding: 0px;
    border-radius: 0px;
}

.center-timeline li span {
    font-size: 13px;
    color: #129ac3;
    line-height: normal;
    font-weight: bold;
}

.center-timeline li .time {
    float: none;
    display: block;
    color: #9fa3a4;
    line-height: normal;
    font-weight: normal;
    margin-top: 10px;
}

.center-timeline-bg {
    background: #fff;
    border: 1px solid #e2e3e3;
    border-radius: 3px;
    clear: right;
    padding: 18px;
    line-height: 20px;
}

.center-timeline-bg:before {
    content: "";
    background: url(/skin/images/arrow_left-v1.png) no-repeat 0px 0px;
    height: 13px;
    width: 13px;
    position: absolute;
    left: 76px;
}

.center-timeline span.material-icons {
    color: #7d7d7d;
    margin-right: 8px;
    position: relative;
    top: 3px;
}

span.wall-blank,
span.wall-del,
span.wall-add,
span.wall-pick,
span.wall-logged,
span.wall-right,
span.wall-complete,
span.wall-move,
span.wall-update {
    width: 22px;
    height: 16px;
    background: url(/skin/images/activity-sprite-icon.png) no-repeat -4px -4px;
    display: block;
    float: left;
}

span.wall-move {
    background-position: -4px -4px;
}

span.wall-logged {
    background-position: -4px -224px;
}

span.wall-logged-out {
    background-position: -4px -443px;
}

span.wall-pick {
    background-position: -4px -497px;
}

span.wall-move {
    background-position: -4px -4px;
}

span.wall-update {
    background-position: -4px -84px;
}

span.wall-complete {
    background-position: -4px -127px;
}

span.wall-add {
    background-position: -4px -172px;
}

span.wall-right {
    background-position: -4px -127px;
}

span.wall-del {
    background-position: -4px -548px;
}

#right-section .ui-state-default,
#right-section .ui-widget-content .ui-state-default,
#right-section .ui-widget-header .ui-state-default {
    background: var(--blue);
    border: 0;
    border-radius: 0;
    border-right: 1px dotted #69a5b7;
}

#right-section .ui-state-default:first-child,
#right-section .ui-widget-content .ui-state-default:first-child,
#right-section .ui-widget-header .ui-state-default:first-child {
    /*border-right:0px;*/
}

#right-section .ui-widget-content .pager,
#right-section .ui-widget-content #pager,
#right-section .ui-widget-content #pager1 {
    background: #fff;
    border: 0px;
    border-top: 1px solid #aaa;
    color: #686e70;
    height: 40px;
}

#right-section .ui-state-default,
#right-section .ui-widget-content .ui-state-default,
#right-section .ui-widget-header .ui-state-default {
    font: bold 13px/22px var(--ff-sans-serif);
    color: #fff;
}

#right-section .ui-jqgrid tr.jqgrow td,
#right-section .ui-jqgrid tr.jqgroup td {
    font: normal 13px/1.5em var(--ff-sans-serif);
    padding: 5px 10px;
    text-align: left !important;
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_cb],
#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=grid_cb],
#right-section .ui-jqgrid tr.ui-jqgrid-labels #list_cb,
#right-section .ui-jqgrid tr.ui-jqgrid-labels #grid_cb {
    padding-left: 6px;
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_percent_sched][title='0.00'],
#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_percent_pick][title='0.00'] {
    background: #ffeeee;
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_is_picked] a,
#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status] a {
    font-size: 11px;
    text-transform: uppercase;
    padding: 3px 7px;
    border-radius: 10px;
    color: var(--white);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_is_picked][title='Pending Pick'] a {
    background: var(--red);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_is_picked][title='Picked'] a {
    background: var(--green);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_is_picked][title='Archived'] a {
    background: var(--aquadark);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status][title='New'] a {
    background: var(--blue);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status][title='Confirmed'] a {
    background: var(--lightblue);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status][title='Located'] a {
    background: var(--green);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status][title='On Hold'] a,
#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status][title='Quarantined'] a,
#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status][title='Damaged'] a {
    background: var(--red);
}

#right-section .ui-jqgrid tr.jqgrow td[aria-describedby=list_status][title='Delivered'] a {
    background: var(--navy);
}

#right-section .ui-jqgrid tr.jqgroup td {
    font-weight: bold;
}

#right-section .ui-jqgrid td a.text-white {
    color: var(--white);
}

#right-section .ui-jqgrid .ui-jqgrid-htable th div {
    font-size: 12px;
    text-align: left;
    padding: 5px 10px;
    text-transform: capitalize;
    text-shadow: 1px 1px var(--black);
}

#right-section .ui-jqgrid .ui-jqgrid-htable th div#jqgh_list_cb,
#right-section .ui-jqgrid .ui-jqgrid-htable th div#jqgh_grid_cb {
    padding-left: 0px;
}

#right-section .ui-widget-header,
.ui-widget .ui-widget-header {
    background: #f0f1f2;
    font-weight: normal;
}

body .ui-jqdialog .ui-jqdialog-titlebar {
    padding: 2px 10px;
}

#inventory_tbl #colmodlist {
    width: 292px !important;
}

#inventory_tbl .ui-widget-overlay {
    position: fixed;
}

body #searchmodfbox_list {
    width: 422px !important;
}

#right-section .ui-jqdialog label,
.ui-widget .ui-jqdialog label {
    font-weight: normal;
    color: #626f75;
}

#right-section .ui-jqdialog td {
    padding: 0px 5px;
}

#right-section .ui-jqdialog-content,
#right-section .ui-jqdialog .ui-jqdialog-content,
body .ui-jqdialog-content,
body .ui-jqdialog .ui-jqdialog-content {
    padding: 0px;
}

#right-section .ui-jqdialog-content .formdata,
#right-section .ui-jqdialog .ui-jqdialog-content .formdata {
    padding: 11px 14px;
}

body .ui-widget-content .searchFilter {
    padding: 11px 14px 11px 5px;
}

#right-section .ui-jqdialog-content .EditTable,
#right-section .ui-jqdialog .ui-jqdialog-content .EditTable {
    padding: 0px;
    background: #f7f8f8;
    border-top: 1px solid #e8e8e8;
    padding-bottom: 8px;
}

body .ui-widget-content .EditTable {
    padding: 0px;
    background: #f7f8f8;
    border-top: 1px solid #e8e8e8 !important;
    padding-bottom: 8px;
}

body .ui-widget-content .searchFilter input.add-rule {
    background: var(--green);
    border: none;
    color: #fff;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
}

body .ui-widget-content .searchFilter input.add-rule:after {
    content: "Add";
    position: absolute;
    right: 0px;
}

body .ui-widget-content .searchFilter input.delete-rule {
    background: url(/skin/images/delete_icon_v1.png) no-repeat 0px 0px;
    border: none;
    cursor: pointer;
    position: relative;
    right: -14px;
    margin-top: 8px;
    width: 20px;
    text-indent: -999em;
}

body .ui-widget-content .searchFilter input,
body .ui-widget-content .searchFilter select {
    border: 1px solid #e1e1e1;
    padding: 4px;
    border-radius: 2px;
}

body .ui-widget-content .searchFilter input {
    padding: 5px;
}

body .ui-widget-content .searchFilter select.opsel {
    margin-left: 5px;
}

body .ui-widget-overlay {
    opacity: .60;
    filter: Alpha(Opacity=60);
    background: #000;
}

body a.ui-state-default,
body .ui-widget-content a.ui-state-default,
body a.ui-state-default,
body .ui-widget-content a.ui-state-disabled {
    border: 0px;
    background: #e5e7e8;
    border-radius: 2px;
    font-weight: normal;
    padding: 2px 10px;
    margin-left: 10px;
    margin-right: 15px;
}

body .ui-datepicker-calendar a.ui-state-default,
body .ui-datepicker-calendar .ui-widget-content a.ui-state-default {
    margin: 0px;
}

body .ui-datepicker-calendar a.ui-state-highlight,
body .ui-datepicker-calendar .ui-widget-content a.ui-state-highlight {
    background: grey;
}

body .ui-datepicker-calendar .ui-datepicker-week-end a {
    background: #dde;
}

body a.ui-state-defaultui-reset,
body .ui-widget-content a.ui-state-default.ui-reset {
    background: var(--green);
    color: #fff;
}

body a.ui-state-default span,
body .ui-widget-content a.ui-state-default span {
    display: none;
}

hr.ui-widget-content {
    border: 0px;
    margin: 0px;
}

body .ui-jqdialog-content td.EditButton {
    padding-bottom: 0px;
    padding-top: 4px;
}


/*
body .ui-widget-header .ui-icon{background:url(/skin/images/close_icon_v1.png) no-repeat 4px 5px;}
body .ui-widget-header .ui-icon:hover{ border:none; background:url(/skin/images/close_icon_v1.png) no-repeat 4px 5px;}
*/
#pg_pager .ui-icon {
    transition-duration: 500ms;
    border: 1px solid #aaa;
    border-radius: 3px;
}

#pg_pager span.ui-icon.ui-icon-calculator {
    background-image: url(/skin/images/column_icon_v1.png);
}

#pg_pager .ui-state-hover .ui-icon,
#pg_pager .ui-state-focus .ui-icon,
#pg_pager #mybutton:hover {
    background-color: #ccc !important;
}

#pg_pager .ui-state-hover,
#pg_pager .ui-state-focus {
    background: none;
    border: 0;
}

#pg_pager #mybutton {
    display: inline-block;
    margin-left: 3px;
    margin-top: 2px;
}

#pg_pager .ui-pg-div #mybutton {
    margin-top: 0;
    margin-left: 0;
}

#pg_pager #pager_center .ui-icon {
    border: 0;
}

.add_product_block {
    width: 64%;
    margin: 0px auto;
}

.add_product_btns {
    margin: 0px 23px;
    text-align: right;
    border-top: 1px solid #dedede;
    padding-top: 27px;
}

.content-padding {
    padding: 20px;
    clear: both;
}

.top-center-links {
    float: left;
    /*width: 440px;*/
}

.top-center-links li {
    list-style: none;
    float: left;
    margin: 8px 3px;
}

.top-center-links li a,
.btn-generic,
.content-top-details input[type=button] {
    background: var(--green);
    /* Old browsers */
    border: 1px solid var(--green);
    color: var(--white);
    border-radius: 3px;

    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 5px 12px 6px;
    text-align: center;
    border-bottom: 5px;
}

.content-top-details a.btn-generic {
    padding: 2px 12px;
    display: inline-block;
}

.top-center-links li a:hover {
    background-color: #090;
}

.buttons {
    list-style-type: none;
}

.buttons li a {
    background: none repeat scroll 0 0 var(--green);
    border: medium none;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    padding: 5px 12px 6px;
    text-align: center;
}

.buttons li a:hover {
    background-color: #090;
}

.buttons-vertical li {
    display: block;
    margin-bottom: 5px;
}

.buttons-vertical li a {
    display: block;
}

.buttons-vertical.with-checkbox label {
    width: 100%;
    display: block;
    padding: 5px 2px;
    background: var(--green);
    text-align: center;
    cursor: pointer;
}

.buttons-vertical.with-checkbox input[type=checkbox] {
    float: left;
}


.buttons-vertical.with-checkbox label span {
    width: 140px;
    text-align: left;
    display: inline-block;
}

.buttons-vertical.with-checkbox label a {
    background: none;
    font-weight: bold;
}

table.template {
    width: 100%
}

table.template th {
    background-color: #008EBD;
    border: 1px solid;
}

table.template td {
    border: 1px solid;
}

table.template td input[type="number"] {
    width: 80px;
}

#print_dialog {
    display: none;
}

.pick-table {
    margin-right: 40px;
}

.pick-table td {
    background: #eee;
    padding: 3px 5px;
    /*
    border-bottom: 1px solid #999;
*/
}


/**
 * Utilities
 */

.block {
    display: block;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clearfix {
    clear: both;
}

.text-right {
    text-align: right;
}

.fade {
    display: none;
}

.fade.in {
    display: block;
}

/**
 * Table Pagination Fix
 */

.pager .ui-state-hover,
.pager .ui-widget-content .ui-state-hover,
.pager .ui-widget-header .ui-state-hover {
    border: 0 !important;
}


/**
 * Buttons, yea global buttons
 */

.button,
.ui-widget .button {
    font-size: 12.5px;
    border-radius: 3px;
    cursor: pointer;
    border: 0;
    padding: 6px 12px;
    display: inline-block;
}

.button.success {
    background: var(--green);
    color: #fff;
}

.button.danger {
    background: #FF6666;
    color: #fff;
}

.button.info {
    background: #0080FF;
    color: #fff;
}

.button.disabled {
    cursor: not-allowed;
    background: #ccc;
    color: #999;
}

.button.expand {
    display: block;
}


/**
 * Global Validation Styles
 * Note: add .validate in <form> element
 */

.validate {
    margin: 15px 0;
}

.validate label {
    margin-right: 5px;
    line-height: 28px;
}

.validate .form-group {
    margin: 0 0 10px 0;
}

.validate input {
    min-width: 80px;
}

.validate input.error {
    border: 1px solid #ff6666;
}

.validate label.error {
    display: block;
    font-size: 11px;
    line-height: 20px;
    color: #f66;
}

.validate label.expand {
    display: block;
    clear: both;
}

.validate input.valid {
    border-color: var(--green);
}

.validate select {
    margin: 0 0 10px 0;
    width: 100%;
}


/**
 * Global content area
 */

.content {
    width: 100%;
    clear: both;
    float: left;
}

.content.fit {
    margin: 0;
}

.content.no-width {
    width: auto;
}


/**
 * New breadcrumbs
 */

.crumbs {
    list-style: none;
    margin: 5px 0 0 0;
}

.crumbs li {
    display: inline;
}

.crumbs li:after {
    content: ' / ';
    color: #ccc;
}

.crumbs li:last-child:after {
    display: none;
}


/**
 * Miscs
 */

.indent-top {
    margin-top: 22px;
}

.form-table {
    width: 60%;
    margin: auto;
}

.form-table label {
    clear: both;
    width: 100%;
    margin: 0 0 3px 0;
}

.form-table input {
    width: 90%;
    padding: 4px 8px;
    border-radius: 3px;
    border: 1px solid #383838;
    background: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}

.form-table select {
    width: 262px;
    background: #fff;
    border: 1px solid #e2e3e3;
    border-radius: 3px;
    padding: 0px 5px;
}

.form-table td {
    padding-bottom: 15px;
}

.form-table td.separator {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #fff;
    padding: 0;
}

.form-table .date-custom input {
    background: #fff url('../images/calendar-icon.gif') no-repeat 97% 50%;
}

.form-table .form-subtable .selectize-control {
    margin-right: 10px;
    min-width: 127px;
}

.form-table .form-subtable .selectize-control.no-right-margin {
    margin-right: 0;
    width: 102%;
}

select.disabled,
input.disabled,
textarea.disabled {
    background: #fafafa;
    color: #ccc;
}

/**
 * Dropdown menu for daysheets page
 * /outward/daysheets
 */

.mimic-input-box {
    background: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 2px 5px;
    cursor: pointer;
    width: 262px;
}

.mimic-input-box:after {
    clear: both;
    content: ' ';
    display: block;
}

.mimic-input-box .item {
    border-radius: 4px;
    background: #eee;
    font-size: 11px;
    padding: 0 8px;
    float: left;
    margin: 3px;
}

.customers-list {
    list-style: none;
    margin: -1px 0 0 0;
    border: 1px solid #e3e3e3;
    background: #fff;
    width: 270px;
    height: 300px;
    overflow: auto;
    display: none;
}

.customers-list-container:hover .customers-list {
    display: block;
}

.customers-list li {
    display: block;
    padding: 5px;
}

.customers-list label {
    cursor: pointer;
}

.customers-list input[type=checkbox] {
    margin-top: 7px;
    width: auto;
}


/**
 * Column Field
 */

.column-field-wrap {
    position: relative;
}

.column-field-wrap .column-settings {
    border: 1px solid #ccc;
    padding: 0 6px;
    z-index: 2;
    position: absolute;
    right: -28px;
    top: 33px;
    font-size: 11px;
    float: right;
}


/**
 * Column Field Settings
 */

.copts-container {
    min-width: 750px;
}

.copts-container .copts-column {
    width: 50%;
    float: left;
}

.copts-container .copts-list {
    list-style: none;
    margin: 10px;
}

.copts-container .copts-list li {
    margin: 4px 0;
    display: block;
}

.copts-container .copts-list .copts-list-items {
    /*border: 1px dashed #ccc;
    border-radius: 3px;*/
    border-bottom: 1px solid #eee;
    margin: 0 0 10px 0;
    padding: 5px 0 15px 0;
    min-height: 60px;
}

.copts-container .copts-list .copts-list-items:last-child {
    border-bottom: none;
}

.copts-container .copts-list .copts-list-items input {
    width: 90%;
}

.copts-container .copts-list .copts-label-action {
    float: right;
    font-size: 10px;
    margin-top: 5px;
}

.copts-container .copts-columns-selected .copts-label {
    color: #0080FF;
}

.copts-container .copts-column .copts-label-desc {
    display: block;
    margin-top: 5px;
    font-size: 11px;
    color: #777;
}

.copts-container .copts-list-title {
    font-weight: bold;
    margin: 10px 10px 20px 10px;
    font-size: 13px;
}

.copts-container .copts-list-title small {
    font-weight: normal;
    color: #999;
}

.copts-container .copts-label {
    margin-right: 20px;
    position: relative;
}

.copts-container .copts-label .input-action {
    background: #eee;
    border-radius: 50px;
    position: absolute;
    right: 30px;
    padding: 4px;
    top: 4px;
    font-size: 10px;
}

.copts-container .copts-label .input-action:hover {
    background: #ddd;
}

.copts-container .copts-label .input-action.save {
    right: 50px;
}

#query {
    height: 44px;
    border: 1px dashed #ccc;
    color: #444;
    padding: 10px;
    overflow: hidden;
    font: normal 11px/22px monospace;
    position: relative;
}

#query:after {
    content: 'Hover for full view';
    position: absolute;
    top: 0;
    right: 0;
    font-size: 9px;
    color: #888;
    background: #ddd;
    padding: 4px;
}

#query:hover {
    background: #fefefe;
    overflow: auto;
    height: auto;
}

#query:hover:after {
    display: none;
}

#query .sc {
    color: brown;
}

#query .keyword {
    color: purple;
    font-weight: bold;
}

#query .string {
    color: darkgreen;
}

#query .comment {
    color: green;
}

#query .function {
    color: red;
}

#actions form {
    float: right;
    margin-left: 5px;
}


/**
 * Global form style
 */

.form {}

.form.centered {
    width: 80%;
    margin: 40px auto;
}

.form.centered label {
    text-align: right;
    margin: 5px 20px 5px 0;
    font-size: 13px;
    color: #222;
    float: left;
    width: 100px;
}

.form.centered .form-group {
    margin: 10px 0;
}

.form.centered .form-group.submit,
.form.centered .form-group.indent {
    margin-left: 120px;
}

.form.centered input,
.form.centered select {
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 200px;
    padding: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form.centered select {
    background: #eee url('../images/down-arrow.jpg') no-repeat 97% 50%;
    width: 210px;
}

.form.centered input[type="submit"] {
    width: auto;
    border: none;
    padding: 6px 10px;
}

.form.centered .separator {
    margin-top: 40px;
}

.unit-list {
    list-style: none;
    margin: 10px 0;
}

.unit-header {
    margin: 0 0 10px 0;
    clear: both;
    float: left;
}

.unit-block {
    list-style: none;
    margin: 10px 0;
}

.unit-block .unit {
    /*width: 25%;
    float: left;*/
}

.unit-block .unit label {
    float: left;
    padding: 5px;
    cursor: pointer;
}

.legend-container {
    list-style-type: none;
    float: right;
}

.legend-container .legend {
    line-height: 30px;
    font-size: 11px;
    float: left;
}

.legend-container .legend span {
    border: 4px solid #000;
    margin: 11px 5px;
    float: left;
}

.table-livesheet {
    /*border-collapse: collapse;*/
    border: 1px solid #000;
}

.table-livesheet th {
    /*border-bottom: 7px solid #ddd;*/
    /*text-align: left;*/
}

.table-livesheet th,
.table-livesheet td {
    border: 1px solid #000;
    padding: 5px 5px;
}

.table-livesheet td {
    font-size: 14px;
}

.table-livesheet .last-row {
    border-bottom: 2px dashed #888;
}

.table-livesheet tr.row-status.new {
    background: #fff;
}

.table-livesheet tr.row-status.cancelled,
#legend-stocks .legend.cancelled span {
    border-color: #FFC3C2;
    background: #FFC3C2;
    color: #FF4242;
}

.table-livesheet tr.row-status.scheduled,
#legend-stocks .legend.scheduled span {
    background: #B8D9FF;
    border-color: #B8D9FF;
}

.table-livesheet tr.row-status.compiling,
#legend-stocks .legend.compiling span {
    border-color: #9EFFB0;
    background: #9EFFB0;
}

.table-livesheet tr.row-status.receiving,
#legend-stocks .legend.receiving span {
    border-color: #FFFAA3;
    background: #FFFAA3;
}

.table-livesheet tr.row-status.completed,
#legend-stocks .legend.completed span {
    border-color: #E43942;
    background: #E43942;
}

.table-livesheet tr.row-status.completed.last-row {
    border-bottom: 2px dashed #902E33;
}

.hidden {
    display: none;
}

dd ul.errors {
    display: block;
    list-style: none;
    padding: 5px;
}

dd ul.errors li {
    color: #EE0000;
}

table.separator,
td.separator {
    border-top: 1px solid #ddd;
}

.button-cross {
    background: url(/skin/images/cross.png) no-repeat 0px 0px;
    border: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.dash-container {
    margin: 20px;
    overflow: hidden;
    border: 1px solid #eee;
}

.half-full:last-child .dash-container {
    margin-right: 20px;
    margin-left: 0;
}

.dash-container h4 {
    font-weight: bold;
    background: #78bcd0;
    padding: 10px;
    color: #fff;
}

#header .selectize-control {
    background: none;
    padding: 0;
    border: 0;
}

#header .selectize-control .selectize-input {
    width: 160px;
    min-height: 30px;
    padding: 2px 8px;
}

#header .selectize-dropdown {
    width: 309px !important;
}

#header .selectize-dropdown [data-selectable] .highlight {
    background: #337ab7;
    color: #fff;
}

.selectize-list {
    border-bottom: 1px solid #eee;
    list-style: none;
    clear: both;
    color: #888;
}

.selectize-list:last-child {
    border-bottom: none;
}

.selectize-list .selectize-list-item {
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.selectize-list .selectize-list-label {
    margin-right: 4px;
    color: #bbb;
}

.selectize-input.locked>.item {
    color: #aaa;
}

.selectize-input.locked:after {
    display: none !important;
}

#container_order {
    position: absolute;
    display: none;
    z-index: 10;
    width: 100%;
    bottom: 0;
    left: 0;
}

.selectize-input.items.required.not-full.has-options {
    border: 1px solid red;
}

.selectized {
    display: block !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 1px;
    border: 0 !important;
    margin: 0 !important;
    position: absolute;
}

#right-section.with-table-headers .ui-jqgrid-titlebar {
    display: block !important;
    background: #4A98AC;
    font-weight: bold;
    color: #fff;
}

#right-section.with-table-headers .jqgroup td {
    padding-top: 5px;
    padding-bottom: 5px;
}

#right-section.with-table-headers .jqgroup .tree-wrap-ltr {
    margin-top: 3px;
    margin-right: 5px;
}

#right-section .ColButton .fm-button {
    margin: 0
}

.report-form-container {
    margin: auto;
    width: 90%;
}

.report-form-container .form-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.report-form-container .form-row {
    margin-bottom: 15px;
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.report-form-container .form-group {
    margin: 30px 72px;
}

.report-form-container .form-group .form-legend {
    border-bottom: 1px solid #e2e3e3;
    padding-bottom: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 14px;

    width: 100%;
}

.report-form-container .form-row .form-label {
    align-items: center;
    display: flex;
    width: 100px;
    margin-right: 10px;
}

.report-form-container .form-row .form-label.align-normal {
    align-items: normal;
}

.report-form-container .form-row .form-input {
    box-shadow: inset 0 1px 1px #e2e3e3;
    border: 1px solid #e2e3e3;
    border-radius: 3px;
    padding: 9px 8px;
    flex: 2;
}

.report-form-container .form-row>.form-column .form-row .form-label+select+.selectize-control {
    margin-right: 72px;
    display: flex;
    flex: 1;
}

.report-form-container .form-row .form-column .form-row>.form-column:first-child .form-input {
    margin-right: 72px;
}

.report-form-container .form-row .form-column .form-row .form-column+.form-column .selectize-control {
    margin-right: 0;
}

.report-form-container .form-row .form-column .form-row .form-column+.form-column .form-label,
.report-form-container .form-row .form-column .form-row .form-column+.form-column .form-submit {
    margin-left: 72px;
}

.report-form-container .form-submit {
    background: rgba(246, 214, 185, 1);
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    border-radius: 3px;
    padding: 7px 10px;
    flex: 1;
}

.report-form-container .selectize-input,
.report-form-container .selectize-input.full,
.report-form-container .form-row .form-input {
    border-color: rgba(179, 204, 227, 1);
    background: rgba(189, 204, 227, 1);
    color: #000;
}

.report-form-container .form-row .form-input {
    box-shadow: inset 0 1px 1px rgba(179, 184, 227, 1);
}


.report-form-container input::placeholder {
    color: #888;
}

/* Quick Filter Styles */
.filter-container {
    --filter-list-color: #095079;
    background: #fff;
    padding: 5px;
}

.filter-container .filter-toggle-link .icon .fa {
    font-size: 10px;
}

.filter-container .filter-toggle-link {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: var(--filter-list-color);
    ;
    display: block;
}

.filter-container .filter-toggle-link.is-filtered {
    background: var(--red);
    margin: -5px;
    padding: 5px;
    color: var(--white);
}

.filter-container .filter-toggle-link .fa {
    width: 10px;
}

.filter-container .filter-options-container {
    display: flex;
    margin-left: 15px;
}

.filter-container .filter-options-container.hide {
    display: none;
}

.filter-container .filter-options-container .filter-option-block {
    margin-right: 20px;
    min-width: 200px;
}

.filter-container .filter-option-block .filter-title {
    border-bottom: 1px solid #444;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: bold;
    padding: 5px 0;
    font-size: 12px;
}

.filter-container .filter-option-block .filter-option-list {
    list-style: none;
}

.filter-container .filter-option-block .filter-option-list li {
    border-bottom: 1px solid #ddd;
    margin: 4px 0;
}


.filter-container .filter-option-block .filter-option-list a {
    transition-duration: 200ms;
    border-radius: 3px;
    display: block;
    padding: 0 5px;
    font-weight: bold;
    line-height: 30px;
    border: 1px solid var(--filter-list-color);
    color: var(--filter-list-color);
    text-transform: uppercase;
    font-size: 11px;
}

.filter-container .filter-option-block .filter-option-list a:hover {
    background: var(--filter-list-color);
    color: #fff;
}

.filter-container .current-filter {
    border-radius: 4px;
    padding: 3px 5px;
    background: var(--filter-list-color);
    color: #000;
}

.filter-container .filter-option-block .filter-option-list .close-icon {
    float: right;
    line-height: 30px;
    visibility: hidden;
}

.filter-container .filter-option-block .filter-option-list .current-filter {
    color: #fff;
}

.filter-container .filter-option-block .filter-option-list .current-filter .close-icon {
    visibility: visible;
    cursor: pointer;
}

.go-to-order {
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    padding: 3px;
    background: #197397;
    color: white
}

.introjs-hints {
    position: relative;
    z-index: 1;
}

/* New Form Layout */
.form-flex {
    background: var(--white);
    margin: 0 20px;
    clear: both;
}

.form-flex .inner {
    margin: 0 auto;
    max-width: 1280px;
    padding-top: 50px;
}

.form-flex .form-column {
    flex: 50%;
}

.form-flex .form-row {
    display: flex;
    padding: 10px;
}

.form-flex .form-row>input:disabled,
.form-flex .form-row>select:disabled,
.form-flex .form-row>input[type=text]:read-only {
    color: var(--gray);
    cursor: not-allowed;
    background: #f5f5f5;
}

.form-flex .form-buttons .button {
    font-size: 16px;
    color: var(--black);
    margin: 10px;
    padding: 10px 0;
    flex: 1 0 auto;
}

.form-flex .form-row>label {
    display: flex;
    flex: 1 0 140px;
    max-width: 220px;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    font-weight: bold;
}

.form-flex .form-row>label.error {
    display: none !important;
}

.form-flex .form-row>input,
.form-flex .form-row>select,
.form-flex .form-row>textarea {
    color: var(--black);
    flex: 1 0 auto;
    line-height: 24px;
    height: auto;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 10%);
}

.form-flex .form-row>textarea {
    margin-right: 10px;
    border-color: #a8a8a8;
    border-radius: 3px;
    border-width: 1px;
}

.form-flex .form-row>select.selectized {
    visibility: hidden;
}

.form-flex .form-row>.selectize-control {
    display: flex;
    flex: 1 0 auto;
    border: 0;
    margin-right: 10px;
}

.form-flex .form-row>input.error,
.form-flex .form-row>select.error {
    background: #FAD6D6;
    border: 1px solid #BB3737;
}

.form-flex .form-row>input:focus {
    background: #95cfff;
}

#form_doc #paperwork_type {
    border: 1px solid #ccc;
    margin: 0 4px;
}

.ui-dialog-content select,
.ui-dialog-content input[type=text] {
    border: 1px solid #ccc;
}

.ui-dialog-content table {
    border-collapse: separate;
}

/* Debug */
div#ZFDebug {
    padding-right: 10px;
    display: flex;
}

div#ZFDebug .ZFDebug_span {
    display: flex;
    align-items: center;
}

div#ZFDebug .ZFDebug_span img {
    margin-right: 5px;
    width: auto;
    height: 16px;
}

div#ZFDebug .ZFDebug_panel {
    scrollbar-width: thin;
    scrollbar-color: #1E3D13 #6F6F6F;
}

/* Chrome, Edge and Safari */
div#ZFDebug .ZFDebug_panel::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

div#ZFDebug .ZFDebug_panel::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #6F6F6F;
}

div#ZFDebug .ZFDebug_panel::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

div#ZFDebug .ZFDebug_panel::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

div#ZFDebug .ZFDebug_panel::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #1E3D13;
}

div#ZFDebug .ZFDebug_panel::-webkit-scrollbar-thumb:hover {
    background-color: #6F6F6F;
}

div#ZFDebug .ZFDebug_panel::-webkit-scrollbar-thumb:active {
    background-color: #292929;
}
