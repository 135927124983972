@config '../../../../tailwind.legacy.config.js';
@plugin "@tailwindcss/forms";

@tailwind base;
@tailwind components;
@tailwind utilities;

@theme {
    --font-sans: InterVariable, sans-serif;
}

@layer components {
    .dropdown-container {
        @apply transition-[opacity,margin] duration-100 hs-dropdown-open:opacity-100 opacity-0 hidden z-10
    }

    .dropdown-wrapper {
        @apply divide-y divide-gray-200 divide-solid hs-dropdown-open:ease-in
            hs-dropdown-open:opacity-100 hs-dropdown-open:scale-100 transition ease-out opacity-0
            scale-95 duration-200 mt-2 origin-top-right min-w-60 bg-white shadow-md rounded border border-solid border-gray-200
    }

    .dropdown-title {
        @apply block pt-2 pb-1 px-3 !text-xs font-medium uppercase text-gray-400
    }

    .dropdown-link {
        @apply flex items-center gap-x-3.5 py-2 px-3 font-medium
            text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100
    }

    .brand-gradient {
        @apply bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-green-600 via-teal-600 to-lime-600
    }

    .sidebar-link {
        @apply text-white font-[500] inline-flex w-full rounded hover:!text-white text-[13px]/8 capitalize
    }

    .hs-accordion-content .sidebar-link {
        @apply ps-5
    }

    .whstab {
        @apply hs-tab-active:bg-gray-50 hs-tab-active:border-teal-500 font-semibold hs-tab-active:text-black p-3 inline-flex items-center gap-x-2 border-e-2 border-transparent text-sm whitespace-nowrap text-gray-500 hover:text-teal-600 focus:outline-none focus:text-teal-600 disabled:opacity-50 disabled:pointer-events-none
    }

    .whstabpanel {
        @apply p-4 w-full
    }

    .whstabsub {
        @apply inline-flex flex-auto font-semibold rounded-full border border-solid border-transparent p-2 justify-center
            hs-tab-active:text-sky-500 hs-tab-active:bg-white hs-tab-active:border-gray-200 hs-tab-active:shadow-sm
    }


    .whs-button {
        @apply py-1.5 px-3.5 inline-flex items-center gap-x-2 text-sm font-medium rounded border border-transparent
    }
}

/* datatable styles */
.dt-layout-row:has(.dt-search),
.dt-layout-row:has(.dt-length),
.dt-layout-row:has(.dt-paging) {
  display: none !important;
}

.dt-empty {
    @apply px-2.5 py-2 text-xs;
}

/* jqGrid theme */
#gbox_list {
    @apply border-b-0;

    .ui-datepicker {
        width: auto;
    }

    .ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
        color: var(--black);
    }

    .edit-cell.ui-state-highlight input {
        font-family: var(--ff-sans-serif);
        width: 100%;
    }

    .ui-jqgrid-bdiv {
        background: azure;
    }

    .ui-pg-input, .ui-pg-selbox {
        @apply border border-solid rounded border-gray-300 !text-xs
    }

    .ui-pg-selbox {
        @apply pl-1 py-1;
        height: 24px;
    }

    .ui-pg-input {
        @apply p-0;
        height: 24px;
    }

    #pager_center {
        @apply text-xs/10;

        .ui-pg-table td {
            @apply px-1
        }
    }

    #pager_right {
        @apply text-xs/10;
    }
}

/* fancygrid overrides */
.fancy-theme-bootstrap {
    @apply !shadow-none !border-none;
    * { @apply font-sans shadow-none; }

    &.fancy-panel {
        .fancy-panel-tbar {
            @apply border-none bg-transparent p-0;
            @apply !h-auto;

            .fancy-toolbar-tab {
                @apply bg-sky-100 text-[13px];
                @apply border border-sky-200 border-solid border-b-0 rounded-tl rounded-tr;
                @apply !w-auto !h-auto;
                padding: 7px 12px 10px 12px;

                &:first-child {
                    @apply ml-0;
                }

                &.fancy-toolbar-tab-active {
                    @apply bg-sky-300 border-sky-400;
                }

                .fancy-button-text {
                    @apply !text-black top-0 p-0;
                }
            }

            /* scroller buttons used inside the fancygrid tabs */
            .fancy-bar-left-scroller, .fancy-bar-right-scroller {
                @apply border-none bg-transparent;
                .fancy-button-image {
                    @apply rounded-full bg-sky-200 border-sky-400 border-solid border;
                    @apply h-[22px] p-[12px];
                    background-position: center center !important;
                }

                &:active {
                    @apply shadow-none;
                }
            }

            /* fix the right offset position so it's not cut off */
            .fancy-bar-right-scroller {
                @apply !right-[8px];
            }
        }

        .fancy-panel-body {
            @apply bg-transparent;

            /* applies to inner grid */
            .fancy-panel-body-inner {
                .fancy {
                    @apply font-sans text-[12px];

                    .fancy-grid-header {
                        @apply border-b border-solid border-gray-200 z-10;
                        .fancy-grid-header-cell-container {
                            @apply !h-auto;
                            padding: 7px 8px;
                        }
                    }
                }

                .fancy-grid-unselectable {
                    .fancy-grid-body {
                        border-bottom: 1px solid #ccc;
                        top: -1px;
                    }
                }
            }

            .fancy-panel-buttons {
                @apply !h-[36px];
            }
        }
    }

    &.fancy-loadmask {
        @apply !bg-transparent;

        .fancy-loadmask-inner {
            @apply bg-sky-100;

            .fancy-loadmask-text {
                @apply font-sans;
            }
        }
    }
}

/* jQuery UI overrides */
.ui-dialog.ui-widget.ui-widget-content {
    border-radius: 5px;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    @apply !text-sm !font-sans
}
